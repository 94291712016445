import React, { useState } from "react"
import { Link } from "gatsby"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import logo from "../assets/images/logo.png"
import { SplitButton } from "react-bootstrap"

const Header = () => {
  const [show, setShow] = useState(false)
  const [show2, setShow2] = useState(false)

  const showDropdown = e => {
    setShow(!show)
  }
  const hideDropdown = e => {
    setShow(false)
  }
  const showDropdown2 = e => {
    setShow2(!show2)
  }
  const hideDropdown2 = e => {
    setShow2(false)
  }

  return (
    <Navbar expand="lg" className="justify-content-between" variant="dark">
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img
              src={logo}
              alt="logo"
              className="logo"
              height="110"
              width="110"
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse
          id="basic-navbar-nav"
          className="ham justify-content-end"
        >
          <Nav>
            <Nav.Link as={Link} to="/" className="style-links">
              Home
            </Nav.Link>
            <SplitButton
              title="About us"
              onMouseEnter={showDropdown}
              onMouseLeave={hideDropdown}
              show={show}
              onClick={showDropdown}
              className="style-links"
              href="/about"
            >
              <Link to="/who-we-are" className="dropdown-item">
                Who We Are
              </Link>
              <Link to="/what-we-do" className="dropdown-item">
                What We Do
              </Link>
            </SplitButton>
            <SplitButton
              title="Operations"
              onMouseEnter={showDropdown2}
              onMouseLeave={hideDropdown2}
              onClick={showDropdown2}
              show={show2}
              className="style-links"
              href="/operations"
            >
              <Link to="/about-the-farms" className="dropdown-item">
                About The Farms
              </Link>
              <Link to="/laboratory" className="dropdown-item">
                Laboratory
              </Link>
              <Link
                to="/sustainability-health-security"
                className="dropdown-item"
              >
                Sustainability, Health & Security
              </Link>
            </SplitButton>
            <Nav.Link as={Link} to="/gallery" className="style-links">
              Gallery
            </Nav.Link>
            <Nav.Link as={Link} to="/blog" className="style-links">
              Blog
            </Nav.Link>
            <Nav.Link as={Link} to="/our-products" className="style-links">
              Our Products
            </Nav.Link>
            <Nav.Link as={Link} to="/contact-us" className="style-links">
              Contact us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

export default Header
